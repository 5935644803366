import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Section>
      <article className="prose lg:prose-xl">
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </article>
    </Section>
  </Layout>
)

export default NotFoundPage
